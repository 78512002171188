import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import "./layout.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
          <main>{children}</main>
          {/* <footer>
            <section className="section has-background-grey-dark">
              <div className="container">
                <p className="title has-text-white">Francisco Vera</p>
                <EmailMe />
              </div>
            </section>
          </footer> */}
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
